import React, { useState, useEffect } from "react";

import Manager from "./RepubManager";
import Row from "./RepubRow";
import Status from "../../../utils/Stage";
import axios from "../../../service/api";
import { ReactComponent as Expand } from "../../../assets/expand.svg";
import { ReactComponent as Shrink } from "../../../assets/uparrow.svg";
import ErrorPoup from "../../Popup/ErrorPopup";
import { RiFileExcel2Line } from "react-icons/ri";
import "../../../pages/ManageEmployee/LoadingSpinner.css";
import DataSubmittingPop from "../../Popup/DataSubmitPopup";
import "../../Layout/animationsActive.css";

const RepubData = React.memo((props) => {
  const [data, setData] = useState(props.data);
  const [showPopUp, setShowPopup] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [showFilter, setFilterVisibility] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fromDate, setFromDate] = useState(new Date()); // State for fromDate
  const [endDate, setEndDate] = useState(new Date());
  const [disabledCheckboxes, setDisabledCheckboxes] = useState(false);
  const [showExportDropdown, setExportDropdownVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const tempPaginationCount = +(props.totalItem || 10) / (props.perPage || 10);
  let totalPage = 1;
  const [visibleColumnIndices, setVisibleColumnIndices] = useState(
    (() => {
      return props.title.toLowerCase() === "users" ||
        props.title.toLowerCase() === "pub codes"
        ? [...props.visibleColumnIndices]
        : [0, ...props.visibleColumnIndices];
    })()
  );

  const saveVisibleColumnIndicesToLocalStorage = (indices) => {
    localStorage.setItem("allocationFilesOptions", JSON.stringify(indices));
  };

  const saveVisibleColumnIndicesToLocalStorageEpub = (indices) => {
    localStorage.setItem("allocationEpubFilesOptions", JSON.stringify(indices));
  };

  const saveVisibleColumnIndicesToLocalStorageProd = (indices) => {
    localStorage.setItem("productionFilesOptions", JSON.stringify(indices));
  };

  const saveVisibleColumnIndicesToLocalStorageEpubProd = (indices) => {
    localStorage.setItem("productionEpubFilesOptions", JSON.stringify(indices));
  };

  // useEffect(() => {
  //   const savedIndices = localStorage.getItem("allocationFilesOptions");
  //   if (savedIndices) {
  //     setVisibleColumnIndices(JSON.parse(savedIndices));
  //   }
  // }, []);

  console.log(props.cols.indexOf("Allocation"));
  console.log(
    visibleColumnIndices.length,
    "hello",
    props.cols.filter(
      (item) => item !== undefined && item !== "_id" && item !== "Allocation"
    ).length
  );
  console.log(props.data);
  visibleColumnIndices.sort((a, b) => a - b);
  console.log(visibleColumnIndices, "hi");
  const [sortOrder, setSortOrder] = useState();
  const [sortByCol, setSortByCol] = useState();
  const [searchBarValue, setSearchBarValue] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [defaultCheckValue, setDefaultCheckValue] = useState(false);

  const [checkedList, setCheckedList] = useState([]);
  let isFirstTime = false;
  useEffect(() => {
    if (!isFirstTime) {
      manipulateCheckedList(false);
      isFirstTime = true;
    }
  }, [isFirstTime]);

  // const handleComplete = (updatedDataWithoutCompleted) => {
  //   // Update the data in the parent component
  //   setData(updatedDataWithoutCompleted);
  // };

  const onCheckChange = (e) => {
    if (e.target.checked) {
      setCheckedRows([...props.data]);
      manipulateCheckedList(true);
      // setDefaultCheckValue(true);
      setIsChecked(true);
    } else {
      setCheckedRows([]);
      manipulateCheckedList(false);
      // setDefaultCheckValue(false);
      setIsChecked(false);
    }
  };

  const manipulateCheckedList = (value) => {
    let tempList = [];
    data.forEach((element) => {
      tempList.push(value);
    });
    setCheckedList([...tempList]);
  };
  const generateCols = () => {
    const requiredManager = props.requiredManager;
    // const isProductionVisible = visibleColumnIndices.includes(
    //   props.cols.indexOf("Production")
    // );
    // const isQAVisible = visibleColumnIndices.includes(props.cols.indexOf("QA"));
    // const isFinalVisible = visibleColumnIndices.includes(
    //   props.cols.indexOf("Final")
    // );

    // Remove "Final" columns from visibleColumnIndices
    const finalIndex = props.cols.indexOf("Final");
    if (visibleColumnIndices.includes(finalIndex)) {
      visibleColumnIndices.splice(visibleColumnIndices.indexOf(finalIndex), 1);
    }

    const downloadButton = visibleColumnIndices.includes(
      props.cols.indexOf("Download")
    );

    if (requiredManager === 6) {
      if (downloadButton) {
        const downloadIndex = props.cols.indexOf("Download");
        visibleColumnIndices.splice(
          visibleColumnIndices.indexOf(downloadIndex),
          1
        );
      }
    }

    let swapIndex;
    if (props.cols.indexOf("filename") !== -1) {
      swapIndex = props.cols.indexOf("filename");
    }

    if (swapIndex !== -1) {
      let temp = props.cols[swapIndex];
      props.cols[swapIndex] = props.cols[0];
      props.cols[0] = temp;
    }

    let swapIndexTat;
    if (props.cols.indexOf("TAT") !== -1) {
      swapIndexTat = props.cols.indexOf("TAT");
    }

    if (swapIndexTat !== -1) {
      let temp = props.cols[swapIndexTat];
      props.cols[swapIndexTat] = props.cols[7];
      props.cols[7] = temp;
    }

    const sortDataset = (col, sortOrderLocal) => {
      const dataCopy = [...data];
      dataCopy.sort((a, b) =>
        sortOrderLocal === "asc"
          ? a[col] > b[col]
            ? 1
            : -1
          : a[col] < b[col]
          ? 1
          : -1
      );
      setData([...dataCopy]);
    };

    return (
      <tr className="py-4 px-6 w-full whitespace-nowrap">
        <th className="px-2 capitalize cursor-pointer py-2">
          <input
            onChange={onCheckChange}
            type="checkbox"
            className="hidden"
            id={`checkbox-${props.index}`}
            name="checker"
            // disabled={disabledCheckboxes}
            defaultChecked={isChecked}
          />
          <label
            htmlFor={`checkbox-${props.index}`}
            className="block h-6 w-6 border-2 border-blue-500 rounded-sm relative"
            style={{ borderRadius: "0.25rem" }}
          >
            <svg
              className={`h-7 w-8 text-blue-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                isChecked ? "block" : "hidden"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M14.707 6.293a1 1 0 0 1 0 1.414L8.414 14l-4.707-4.707a1 1 0 0 1 1.414-1.414L8 11.586l5.293-5.293a1 1 0 0 1 1.414 0z"
                // d="M6.293 10.293a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1-1.414 1.414L10 8.414l-2.707 2.707a1 1 0 0 1-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </label>
        </th>
        {visibleColumnIndices.map((value, i) => {
          console.log(i, value);
          if (props.cols[value] === "_id") {
            return null;
          }
          return (
            <th className="px-5 capitalize cursor-pointer py-4" key={i}>
              <div
                onClick={(e) => {
                  if (sortOrder === "asc") {
                    e.preventDefault();
                    setSortOrder("desc");
                    setSortByCol(props.cols[value]);
                    sortDataset(props.cols[value], "desc");
                  } else {
                    e.preventDefault();
                    setSortOrder("asc");
                    setSortByCol(props.cols[value]);
                    sortDataset(props.cols[value], "asc");
                  }
                }}
                className="flex items-center"
              >
                {((val) => {
                  console.log(props.cols[val]);
                  return props.cols[val];
                })(value)}
                {!props.unsortableFields?.includes(props.cols[value]) && (
                  <div className="flex items-center flex-col">
                    <button
                      className="mx-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setSortOrder("asc");
                        setSortByCol(props.cols[value]);
                        sortDataset(props.cols[value], "asc");
                      }}
                    >
                      <Shrink
                        fill={
                          sortOrder === "asc" && sortByCol === props.cols[value]
                            ? "#000000"
                            : "#999999"
                        }
                      />
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setSortOrder("desc");
                        setSortByCol(props.cols[value]);
                        sortDataset(props.cols[value], "desc");
                      }}
                      className="-mt-3"
                    >
                      <Expand
                        fill={
                          sortOrder === "desc" &&
                          sortByCol === props.cols[value]
                            ? "#000000"
                            : "#999999"
                        }
                      />
                    </button>
                  </div>
                )}
              </div>
            </th>
          );
        })}
        {props.requiredManager === 4 &&
        props.title === "Files" &&
        (props.currentPageType === "All" && 
        props.data.some(item => item.Status === "QA Completed")) || 
        props.currentPageType === Status.QACOMP ? (
          <th className="px-5 capitalize cursor-pointer">
            {/* {" "} */}
            <div className="flex items-center">Actions</div>{" "}
          </th>
        ) : (
          <></>
        )}
        {props.actionCenter === 1 ? (
          <th className="px-5 capitalize cursor-pointer py-4">
            {" "}
            <div className="flex items-center">Actions</div>{" "}
          </th>
        ) : (
          <></>
        )}
        {props.actionCenter === 2 ? (
          <th className="px-5 capitalize cursor-pointer py-4">
            {" "}
            <div className="flex items-center">Allocations</div>{" "}
          </th>
        ) : (
          <></>
        )}
        {props.actionCenter === 1 ? (
          <th className="px-5 capitalize cursor-pointer py-4">
            <div className="flex items-center">Allocated Time</div>
          </th>
        ) : (
          <></>
        )}
      </tr>
    );
  };

  const handleDownloadExcelReport = async () => {
    try {
      const url = `/report/repubMasterExcel?fromDate=${fromDate}&toDate=${endDate}`;

      const response = await axios.get(url, {
        responseType: "blob", // Indicate that the response should be treated as a Blob
      });

      // Create a URL for the Blob response
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger a click to download the file
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "data.xlsx");
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExcelEpub = async (event) => {
    try {
      setIsLoading(true);
      const file = event.target.files[0];
      if (!file) return;

      // Use FileReader to read the file
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = e.target.result;
          const response = await axios.post("/files/excelEpublUploadFile", {
            fileData: data,
            // onUploadProgress: (progressEvent) => {
            //   // Calculate progress percentage
            //   const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            //   // Update progress state using setState updater function
            //   // setImportProgress(prevProgress => progress);
            // },
          });

          alert("Epub data imported successfully");
          console.log("Epub data imported successfully:", response.data);
        } catch (error) {
          alert("Error importing epub data. Please check the file format.");
          console.error("Error parsing Excel data:", error);
        } finally {
          setIsLoading(false);
        }
      };

      reader.readAsBinaryString(file);
    } catch (error) {
      console.error("Error while importing roster:", error);
    }
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const valueChange = (e) => setSearchBarValue(e.target.value);

  const setListCheckStatus = (value, index) => {
    let checkListTemp = checkedList;
    checkListTemp[index] = value;
    setCheckedList([...checkListTemp]);
  };

  const generateRows = () => {
    return props.data
      .filter((obj) => {
        if (props.title.toLowerCase() === "users") {
          return selectedFilter.toUpperCase() !== "ALL"
            ? obj.role.toUpperCase() === selectedFilter.toUpperCase()
            : true;
        } else {
          return selectedFilter.toUpperCase() !== "ALL"
            ? obj.Status.toUpperCase() === selectedFilter.toUpperCase()
            : true;
        }
      })
      .filter((obj) => Object.values(obj).join("").includes(searchBarValue))
      .map((obj, i) => {
        return (
          <Row
            actions={props.actions}
            requiredManager={props.requiredManager}
            key={i}
            index={i}
            item={obj}
            data={props.data}
            page={props.currentPageType}
            title={props.title}
            // page={props.type}
            actionCenter={props.actionCenter}
            setData={props.setData}
            onCheckedChange={(value, index) => setListCheckStatus(value, index)}
            defaultChecked={checkedList[i]}
            visibleColumnIndices={visibleColumnIndices}
            cols={props.cols}
            refreshList={props.refreshList}
            readOnlyStatus={props.readOnlyStatus}
            checkedRows={checkedRows}
            setCheckedRows={setCheckedRows}
            needPaddedRows={props.needPaddedRows}
          />
        );
      });
  };

  return (
    <>
      <div
        className={`relative px-12 mb-8 ${showFilter ? "py-8" : "py-0"} ${
          showFilter ? "bg-white" : "bg-[#F2F6FF"
        }`}
      >
        <button
          className={`flex absolute z-10 uppercase right-5 text-black font-bold px-10 bg-white ${
            showFilter ? "-bottom-3" : "top-2"
          }`}
          onClick={() => {
            setFilterVisibility(!showFilter);
            setExportDropdownVisibility(false);
          }}
        >
          Filters{" "}
          <span className="ml-3">
            {showFilter ? (
              <Expand className="transform rotate-180" />
            ) : (
              <Expand className="transform rotate-360" />
            )}
          </span>
        </button>
        {!showFilter &&
          props.title.toLowerCase() === "epub files" &&
          props.requiredManager === 4 && (
            <div className="flex items-center">
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleExcelEpub}
                className="hidden"
                id="epubFileInput"
              />
              <label
                htmlFor="epubFileInput"
                className="cursor-pointer bg-gray-600 px-5 py-2 rounded-lg text-white text-sm mt-2 flex items-center"
              >
                <RiFileExcel2Line className="mr-2" /> Import Excel
              </label>
            </div>
          )}
    
        {showExportDropdown && (
          <div className="absolute top-0 left-5 p-6">
            <div className="flex items-center space-x-4">
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  name="start"
                  type="date"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select date start"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
              <span class="text-gray-500">to</span>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  name="end"
                  type="date"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select date end"
                  value={endDate}
                  onChange={handleEndDateChange}
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>

            <button
              className="block w-full text-left mb-2 px-1 py-0 rounded bg-blue-500 text-white hover:bg-blue-700 transition duration-300"
              onClick={handleDownloadExcelReport}
            >
              Download Excel
            </button>
          </div>
        )}

        <div
          className={` transition-all overflow-scroll duration-500 ${
            !showFilter ? "h-0" : "h-96"
          }`}
        >
          <h2 className="font-semibold capitalize">columns name</h2>
          <div className="capitalize grid grid-cols-8 gap-x-6 gap-y-3">
            <div className={` flex items-center`}>
              <input
                type="checkbox"
                //  key={props._id}
                checked={
                  visibleColumnIndices.length ===
                  props.cols.filter(
                    (item) =>
                      item !== undefined &&
                      item !== "_id" &&
                      item !== "Allocation"
                  ).length
                }
                onChange={(e) => {
                  const visibleColumnIndicesCopy = [];
                  const tempCols = [
                    ...props.cols.filter((item) => item !== undefined),
                  ];
                  console.log(props.cols);
                  //     tempCols.splice(tempCols.indexOf("_id"), 1);
                  if (e.target.checked) {
                    console.log("before slice", tempCols);
                    if (props.cols.indexOf("filename") !== -1) {
                      tempCols.forEach((value, index) => {
                        if (value !== "_id" && value !== "Allocation") {
                          console.log(value);
                          visibleColumnIndicesCopy.push(index);
                        }
                      });
                    } else {
                      tempCols.forEach((value, index) => {
                        if (value !== "_id" && value !== "Allocation") {
                          console.log(value);
                          visibleColumnIndicesCopy.push(index + 1);
                        }
                      });
                    }
                    console.log("brakpoint");
                    console.log(visibleColumnIndicesCopy);
                    setVisibleColumnIndices([...visibleColumnIndicesCopy]);
                  } else {
                    console.log(visibleColumnIndices);
                    if (props.cols.indexOf("filename") !== -1)
                      setVisibleColumnIndices([0]);
                    else setVisibleColumnIndices([]);
                  }
                }}
              />

              <span className="ml-3 mr-6">All</span>
            </div>
            {(() => {
              return props.cols.map(
                (col, i) =>
                  col && (
                    <div
                      className={`${
                        col === "_id" || col === "Allocation" ? "hidden" : ""
                      } flex items-center`}
                    >
                      <input
                        type="checkbox"
                        key={i}
                        checked={visibleColumnIndices.includes(i)}
                        onChange={(e) => {
                          const visibleColumnIndicesCopy = [
                            ...visibleColumnIndices,
                          ];

                          if (e.target.checked) {
                            visibleColumnIndicesCopy.push(i);
                          } else {
                            if (visibleColumnIndicesCopy.indexOf(i) !== -1) {
                              visibleColumnIndicesCopy.splice(
                                visibleColumnIndicesCopy.indexOf(i),
                                1
                              );
                            }
                          }
                          console.log(visibleColumnIndicesCopy);
                          setVisibleColumnIndices([
                            ...visibleColumnIndicesCopy,
                          ]);
                        }}
                      />

                      <span className="ml-3 mr-6">{col}</span>
                    </div>
                  )
              );
            })()}
          </div>
          <div className="w-full mb-5 flex items-end justify-end">
            <button
              className="bg-blue-500 px-5 py-2 text-white hover:text-black hover:bg-blue-200 hover:border-blue-500 hover:border-2 hover:border-solid"
              onClick={async () => {
                //  visibleColumnIndices
                try {
                  setShowProgress(true);
                  setFilterVisibility(!showFilter);
                  if (props.title.toLowerCase() === "files") {
                    let tempData = [...visibleColumnIndices];
                    // if (tempData.indexOf(0) === -1) {
                    //   // Add "filename" to the beginning of the array
                    //   tempData.unshift(0);
                    // }
                    tempData.splice(tempData.indexOf(0), 1);
                    await axios.post("/options/updateOptions", {
                      options: tempData,
                      type: "allocation",
                    });
                    saveVisibleColumnIndicesToLocalStorage(tempData);
                  } else if (props.title.toLowerCase() === "epub files") {
                    let tempData = [...visibleColumnIndices];
                    // if (tempData.indexOf(0) === -1) {
                    //   // Add "filename" to the beginning of the array
                    //   tempData.unshift(0);
                    // }
                    tempData.splice(tempData.indexOf(0), 1);
                    // console.log('check epub data index......', tempData)
                    await axios.post("/options/updateOptions", {
                      options: tempData,
                      type: "allocationEpub",
                    });
                    saveVisibleColumnIndicesToLocalStorageEpub(tempData);
                  } else if (props.title.toLowerCase() === "allocated files") {
                    let tempData = [...visibleColumnIndices];
                    tempData.splice(tempData.indexOf(0), 1);
                    // if (tempData.indexOf(0) === -1) {
                    //   // Add "filename" to the beginning of the array
                    //   tempData.unshift(0);
                    // }
                    // tempData.splice(tempData.indexOf(0), 1);

                    await axios.post("/options/updateOptions", {
                      options: tempData,
                      type: "production",
                    });
                    saveVisibleColumnIndicesToLocalStorageProd(tempData);
                  } else if (
                    props.title.toLowerCase() === "allocated epubfiles"
                  ) {
                    let tempData = [...visibleColumnIndices];
                    tempData.splice(tempData.indexOf(0), 1);
                    // if (tempData.indexOf(0) === -1) {
                    //   // Add "filename" to the beginning of the array
                    //   tempData.unshift(0);
                    // }
                    // tempData.splice(tempData.indexOf(0), 1);

                    await axios.post("/options/updateOptions", {
                      options: tempData,
                      type: "productionEpub",
                    });
                    saveVisibleColumnIndicesToLocalStorageEpubProd(tempData);
                  } else if (props.title.toLowerCase() === "ftp details") {
                    await axios.post("/options/updateOptions", {
                      options: visibleColumnIndices,
                      type: "ftp",
                    });
                  } else if (props.title.toLowerCase() === "pub codes") {
                    await axios.post("/options/updateOptions", {
                      options: visibleColumnIndices,
                      type: "pub",
                    });
                  } else if (props.title.toLowerCase() === "users") {
                    await axios.post("/options/updateOptions", {
                      options: visibleColumnIndices,
                      type: "users",
                    });
                  }
                  setShowProgress(false);
                } catch (error) {
                  setShowPopup(true);
                  setShowProgress(false);
                }
              }}
            >
              Apply Changes
            </button>
          </div>
        </div>
      </div>
      {props.title === "Files" && (
        <div className="bg-yellow-200 p-4 mb-4 relative">
          <p className="text-lg font-semibold">
            <span className="text-red-500 animate-blink">
              Important Notice:
            </span>{" "}
            <span className="text-blue-600 font-bold break-words whitespace-pre-line">
              Check Textual Issue by creating normal article. If there's a
              textual issue, try creating the article without spaces (Shift +
              C). If the issue persists, inform the Shift Incharge.
            </span>
          </p>
        </div>
      )}

      {/* </div> */}

      <div className="flex flex-col md:flex-row items-center justify-between mt-2">
        <h2 className="text-3xl font-semibold mb-2 md:ml-4">{props.title}</h2>
      </div>

      <div
        className={`flex flex-wrap text-lg mb-4 space-x-2 space-y-2 cursor-pointer ml-2 ${
          props.requiredManager === 1 ? "hidden" : ""
        }`}
      >
        {props.actions && props.title !== "Allocated Files" ? (
          <>
            <input
              type="radio"
              name="action"
              id="all-selections"
              className="hidden"
              onChange={() => props.onTypeChange("All")}
              checked={"ALL" === props.currentPageType.toUpperCase()}
            />
            <label
              className={`cursor-pointer ${
                "ALL" === props.currentPageType.toUpperCase()
                  ? "text-blue-600"
                  : ""
              }`}
              htmlFor="all-selections"
            >
              All ({props.allCount})
            </label>
          </>
        ) : (
          <div
            className={`hidden ${
              props.title === "Allocated Files" ? "hidden" : ""
            }`}
          >
            <input
              type="radio"
              name="action"
              id="all-selections"
              className="hidden"
              onChange={() => props.onTypeChange("All")}
              checked={"ALL" === props.currentPageType.toUpperCase()}
            />
            <label
              className={`cursor-pointer ${
                "ALL" === props.currentPageType.toUpperCase()
                  ? "text-blue-600"
                  : ""
              }`}
              htmlFor="all-selections"
            >
              All ({props.allCount})
            </label>
          </div>
        )}

        {props.actions
          ?.split("|")
          .filter((action) => !action.includes("Delivered"))
          .map((action, i) => {
            const isActive =
              action.split("(")[0].toUpperCase() ===
              props.currentPageType.toUpperCase();
            const labelClassName = isActive ? "text-blue-600" : "";

            return (
              <div key={i} className="cursor-pointer">
                <input
                  type="radio"
                  name="action"
                  id={`${action.split("(")[0]}-${i}`}
                  className="hidden hover:bg-blue-600"
                  checked={isActive}
                  // checked={
                  //   action.split("(")[0].toUpperCase() ===
                  //   props.currentPageType.toUpperCase()
                  // }
                  onChange={() => props.onTypeChange(action.split("(")[0])}
                />
                <label
                  className={`cursor-pointer ${labelClassName}`}
                  htmlFor={`${action.split("(")[0]}-${i}`}
                >
                  &nbsp; {"|"} &nbsp; {action.toUpperCase()}
                </label>
              </div>
            );
          })}
      </div>

      <Manager
        actions={props.actions}
        searchText={props.searchText}
        onSearch={(searchText) => {
          props.onSearch(searchText);
        }}
        onClearSearch={props.onClearSearch}
        requiredManager={props.requiredManager}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        addUrl={props.addUrl}
        deleteUrl={props.deleteUrl}
        searchParams={props.searchParams}
        editUrl={props.editUrl}
        data={props.data}
        setData={props.setData}
        title={props.title}
        handleClientChange={props.handleClientChange}
        // applyDisableCheck={setDisabledCheckboxes}
        // isCheckboxDisabled={disabledCheckboxes.includes(i)} // Pass disabled status
        // disabledCheckbox={}
        // refreshList={() => {
        //   setCheckedRows([]);
        //   setCheckedList([]);
        //   props.refreshList();
        // }}
        inputs={props.inputs}
        checkedRows={checkedRows}
        // setCheckedRows={setCheckedRows}   //New changes
        setCheckedRows={(arr) => {
          setCheckedRows(arr);
        }}
      />

      <div className="mt-5 mx-5 bg-white rounded-2xl">
        <div
          id="scrollableArea"
          className="overflow-x-auto max-w-full"
          style={{ maxWidth: "100vw" }}
        >
          <style>
            {`
      #scrollableArea::-webkit-scrollbar {
        width: 8px;
      }

      #scrollableArea::-webkit-scrollbar-thumb {
        background-color: #4c51bf;
        border-radius: 8px;
      }

      #scrollableArea::-webkit-scrollbar-thumb:hover {
        background-color: #6b7280;
      }
    `}
          </style>
          {/* <table className="w-full table-auto divide-y-2 divide-gray-400 divide-opacity-20">
            <thead>{generateCols()}</thead>

            <tbody>{generateRows()}</tbody>
          </table> */}
          {props.data?.length > 0 ? (
            <table className="w-full table-auto divide-y-2 divide-gray-400 divide-opacity-20">
              <thead>{generateCols()}</thead>
                 <tbody>{generateRows()}</tbody>
            </table>
          ) : (
            <div className="w-full mt-5 font-semibold bg-white shadow-2xl h-32 flex justify-center items-center rounded-lg">
              No data found
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex justify-end items-end mt-5 mb-10 space-x-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (e.target["pgNumber"].value === "") {
              props?.changePage(1);
            } else {
              if (Number.isInteger(tempPaginationCount)) {
                console.log(true);
                totalPage = +`${tempPaginationCount}`.split(".")[0];
              } else {
                console.log(false);
                totalPage = +`${tempPaginationCount}`.split(".")[0] + 1;
              }
              console.log(parseInt(e.target["pgNumber"].value), totalPage);
              if (parseInt(e.target["pgNumber"].value) > totalPage) {
                props?.changePage(totalPage);
              } else if (parseInt(e.target["pgNumber"].value) < 1) {
                props?.changePage(1);
              } else {
                props?.changePage(+e.target["pgNumber"].value);
              }
            }
          }}
          className="mr-8"
        >
          <label>Enter Page Number : </label>
          <input
            type="number"
            id="pgNumber"
            className="w-20 h-10 mr-5 p-1"
            defaultValue={props.currentPage}
          />
          <button
            type="submit"
            className="bg-blue-500 px-5 py-2 rounded-lg text-white"
          >
            GO
          </button>
        </form>
        <div className="flex flex-wrap items-center space-x-1">
          <button
            onClick={() => {
              if (1 !== props.currentPage) {
                props?.changePage(props.currentPage - 1);
              }
            }}
            className="flex mt-2 items-center px-4 py-2 text-gray-500 bg-gray-300 rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 17l-5-5m0 0l5-5m-5 5h12"
              />
            </svg>
          </button>
          {(() => {
            if (Number.isInteger(tempPaginationCount)) {
              console.log(true);
              totalPage = +`${tempPaginationCount}`.split(".")[0];
            } else {
              console.log(false);
              totalPage = +`${tempPaginationCount}`.split(".")[0] + 1;
            }
            console.log(
              props.currentPage + 5 <= totalPage
                ? [
                    ...(() => {
                      let temp = [];
                      for (
                        let i = props.currentPage;
                        i < props.currentPage + 5;
                        i++
                      ) {
                        temp.push(i);
                      }
                      return temp;
                    })(),
                  ]
                : (() => {})()
            );
            return (
              props.currentPage + 5 <= totalPage
                ? [
                    ...(() => {
                      let temp = [];
                      for (
                        let i = props.currentPage;
                        i < props.currentPage + 5;
                        i++
                      ) {
                        temp.push(i);
                      }
                      return [...temp];
                    })(),
                  ]
                : (() => {
                    let temp = [];
                    for (let i = props.currentPage; i <= totalPage; i++) {
                      temp.push(i);
                    }
                    return [...temp];
                  })()
            ).map((value, index) => (
              <button
                key={index}
                onClick={() => {
                  if (value !== props.currentPage) {
                    props?.changePage(value);
                  }
                }}
                className={`px-4 mt-2 py-2  bg-gray-200 rounded-md ${
                  value === props.currentPage
                    ? "bg-blue-600 text-white"
                    : "text-gray-700"
                } hover:bg-blue-400 hover:text-white`}
              >
                {value}
              </button>
            ));
          })()}

          <button
            onClick={() => {
              if (totalPage !== props.currentPage) {
                props?.changePage(props.currentPage + 1);
              }
            }}
            className="px-4 mt-2 py-2 text-gray-500 bg-gray-300 rounded-md hover:bg-blue-400 hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          </button>
        </div>
      </div>
      {isLoading && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="spinner"></div>
            </div>
          )}
      {showPopUp ? <ErrorPoup message="Connection Error" /> : <></>}
      {/* {showProgress ? <DataSubmittingPop /> : <></>} */}
    </>
  );
});

export default RepubData;
